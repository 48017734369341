import { ReactElement } from 'react';
import { LinkText } from '@mediahuis/chameleon-react';

import { GTitle, GTitleOrGLogo, isGLogo, isGTitle } from '@hubcms/domain-teaser-grid';
import { BrandedHeadingSubtext, BrandedHeading } from '@hubcms/ui-branded-header';
import { brandedHeaderSublinkIcon, storyblockHeadingWithBottomGap } from '@hubcms/brand';

import TeaserGridItem from '../lib/TeaserGrid/TeaserGridItem';
import { parseTitle } from '../utils/parsers/parseTitle';
import { parseLogo } from '../utils/parsers';
import { Logo } from '../ui';

function SubLinkComponent({ subLink }: { subLink: GTitle['data']['subLink'] }) {
  if (!subLink) {
    return null;
  }
  if (subLink.onClick) {
    return (
      <BrandedHeadingSubtext>
        <LinkText as="button" iconRight={brandedHeaderSublinkIcon} onClick={subLink.onClick}>
          {subLink.text}
        </LinkText>
      </BrandedHeadingSubtext>
    );
  }
  return (
    <BrandedHeadingSubtext>
      <LinkText href={subLink.url} target={subLink.target}>
        {subLink.text}
      </LinkText>
    </BrandedHeadingSubtext>
  );
}

type UseAreaTitleOptions = {
  isRoot?: boolean;
  className?: string;
};

export function useAreaTitle(title?: GTitleOrGLogo | null, { isRoot = false, className }: UseAreaTitleOptions = {}) {
  let titleElement: ReactElement | null = null;
  if (title && isGTitle(title)) {
    const { componentProps } = parseTitle(title, 0);
    const gridProps = {
      hasInverseMarginBlockEnd: !storyblockHeadingWithBottomGap,
      ...title.gridProps,
    };
    titleElement = componentProps ? (
      <TeaserGridItem as="div" key={title.data.text} gridProps={gridProps} className={className}>
        <BrandedHeading {...componentProps} data-testid={isRoot ? 'storyblock-header' : undefined} />
        <SubLinkComponent subLink={title.data.subLink} />
      </TeaserGridItem>
    ) : null;
  }
  if (title && isGLogo(title)) {
    const { componentProps } = parseLogo(title, 0);
    titleElement = componentProps ? (
      <TeaserGridItem as="div" key={title.data.text} gridProps={title.gridProps || {}}>
        <Logo {...componentProps} />
      </TeaserGridItem>
    ) : null;
  }
  const hasTitle = Boolean(titleElement);

  return {
    titleElement,
    hasTitle,
  };
}
